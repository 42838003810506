<template>
  <div>
    <graficos-areas-lista class="px-5" />
  </div>
</template>

<script>
import planos from "@/api/planos";

export default {
  data() {
    return {};
  },
  mounted() {
    this.meuPlano();
  },
  methods: {
    async meuPlano() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await planos.buscar(
          this.$store.state.usuario.usuario.empresa.plano_id
        );

        this.$store.commit(`plano/SET_PLANO`, resposta.data.data);
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>
<style>
.apexcharts-title-text {
  font-family: "Roboto" !important;
  text-shadow: -1px 0px 0px white, 1px 0px 0px white, 0px -1px 0px white,
    0px 1px 0px white;
}
.apexcharts-xaxis-title-text {
  font-size: 16px !important;
  font-family: "Roboto" !important;
  text-shadow: -1px 0px 0px white, 1px 0px 0px white, 0px -1px 0px white,
    0px 1px 0px white;
}

.apexcharts-yaxis-title-text {
  font-size: 14px !important;
  font-family: "Roboto" !important;
  text-shadow: -1px 0px 0px white, 1px 0px 0px white, 0px -1px 0px white,
    0px 1px 0px white;
}
#chart {
  padding: 0 10px;
}
</style>
